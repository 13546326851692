<template>
  <section>
    <TopBanner
      title="Community Lending Program Eligibility Search Tool"
      :src="require('@/assets/images/banners/banner_product.jpg')"
    />
    <v-container class="content-container">
      <template>
        <v-sheet
          v-if="!!histories && histories.length > 0"
          class="my-5"
          outlined
        >
          <v-simple-table style="max-height: 200" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Eligible</th>
                  <th class="text-left">Property Address</th>
                  <th class="text-left">Cencus Tract #</th>
                  <th class="text-left">Date</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(history, index) in histories"
                  :key="'history-' + history.key"
                  :class="index % 2 ? 'grey lighten-1' : ''"
                >
                  <td>
                    <v-icon v-if="history.result.validatedCLP" color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon v-else color="error">mdi-close</v-icon>
                  </td>
                  <td>
                    {{
                      `${history.requestModel.street}, ${history.requestModel.city}, ${history.requestModel.state} ${history.requestModel.zip}`
                    }}
                  </td>
                  <td>{{ history.cencus.censusTract }}</td>
                  <td>
                    {{ history.creationDate | moment("MM/DD/YYYY hh:mm A") }}
                  </td>
                  <td>
                    <v-btn icon @click="deleteHistory(history)">
                      <v-icon color="error">mdi-trash-can</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </template>

      <v-alert border="top" colored-border type="info" elevation="2">
        This is a tool designed to search for community lending eligibility, it
        is not a commitment or decision to lend, Eligibility approval will be
        based on review of the file
      </v-alert>
      <v-form
        ref="addressVaildationForm"
        lazy-validation
        @submit.prevent="validateAddress"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Street"
              v-model="requestModel.street"
              :rules="[required]"
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Zip"
              v-model="requestModel.zip"
              v-mask="'#####'"
              :rules="[required, zipcode]"
              outlined
              @change="changeZip"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              id="city"
              label="City"
              v-model="requestModel.city"
              :rules="[required]"
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              id="state"
              label="State"
              :items="states"
              v-model="requestModel.state"
              :rules="[required]"
              item-value="codeValue"
              item-text="codeValue"
              outlined
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>

        <div class="text-right">
          <v-btn class="my-2" color="primary" type="submit"> Next </v-btn>
        </div>
      </v-form>

      <v-form
        v-if="cencus.show"
        ref="censustractValidationForm"
        lazy-validation
        @submit.prevent="validateCLP"
      >
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col v-if="!!cencus.errorMessage" cols="12">
            <v-alert dense outlined type="error">
              <div>{{ cencus.errorMessage }}</div>
              <div>Please follow the instruction below.</div>
            </v-alert>
            <div>
              <div class="text-h6">
                Step 1. Go to
                <a href="https://geomap.ffiec.gov/ffiecgeomap/" target="_blank"
                  >https://geomap.ffiec.gov/ffiecgeomap/</a
                >
              </div>
              <div class="text-h6">
                Step 2. Bring up the map and enter the address in the search
                <v-sheet width="300" height="auto">
                  <img
                    src="@/assets/images/etc/geomap_example.png"
                    alt="geomap"
                  />
                </v-sheet>
              </div>
              <div class="text-h6">
                Step 3. Enter the "State code", "County Code" and "Tract Code"
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="State Code"
              placeholder="e.g. 00"
              v-model="cencus.stateCode"
              v-mask="'##'"
              :rules="[required]"
              outlined
              :disabled="cencus.lockCountyfips"
              :background-color="cencus.lockCountyfips ? 'grey' : 'default'"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="County Code"
              placeholder="e.g. 000"
              v-model="cencus.countyCode"
              v-mask="'###'"
              :rules="[required]"
              outlined
              :disabled="cencus.lockCountyfips"
              :background-color="cencus.lockCountyfips ? 'grey' : 'default'"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Tract Code"
              placeholder="e.g. 0000.00"
              v-model="cencus.censusTract"
              v-mask="'####.##'"
              :rules="[required]"
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn color="primary" type="submit"> Next </v-btn>
        </div>
      </v-form>

      <v-card elevation="0" v-if="result.show" class="text-center">
        <v-card-text>
          <div class="text-h6">
            <v-alert
              outlined
              :type="result.validatedCLP ? 'success' : 'error'"
              text
            >
              <div>
                {{
                  `${requestModel.street}, ${requestModel.city}, ${requestModel.state} ${requestModel.zip}`
                }}
              </div>
              <div>{{ result.message }}</div>
            </v-alert>
          </div>
        </v-card-text>
      </v-card>
      <v-btn class="my-4" outlined color="error" block @click="reset"
        >Reset</v-btn
      >
    </v-container>
    <Footer />
  </section>
</template>

<script type="text/javascript">
import TopBanner from "@/components/TopBanner";
import Footer from "@/views/layouts/Footer";
import { rules } from "@/mixins";

export default {
  components: {
    TopBanner,
    Footer,
  },
  async mounted() {
    this.states = await this.$store
      .dispatch("lookUpApi/getMstCodeVM", "State")
      .then((resp) => resp.data)
      .catch(() => []);

    this.histories = this.$cookies.isKey("clp_histories")
      ? this.$cookies.get("clp_histories")
      : null;
  },
  data() {
    return {
      histories: null,
      states: [],
      requestModel: {
        street: null,
        city: null,
        state: null,
        zip: null,
      },
      cencus: {
        show: false,
        lockCountyfips: false,
        censusTract: null,
        countyCode: null,
        stateCode: null,
        errorMessage: null,
      },
      result: {
        show: false,
        message: null,
        validatedCLP: false,
      },
    };
  },
  methods: {
    async changeZip() {
      if (this.requestModel.zip != null && this.requestModel.zip.length >= 5) {
        const resp = await this.$store
          .dispatch("publicApi/getCityAndStateByZipcode", this.requestModel.zip)
          .catch((err) => err);
        if (resp.status == 200) {
          this.requestModel.state = resp.data.state;
          this.requestModel.city = resp.data.city;
          this.cencus.stateCode = resp.data.countyFips.slice(0, 2);
          this.cencus.countyCode = resp.data.countyFips.slice(2);
          this.cencus.lockCountyfips = true;
        } else {
          this.cencus.stateCode = null;
          this.cencus.countyCode = null;
          this.cencus.lockCountyfips = false;
        }
      }
    },
    async validateAddress() {
      try {
        if (this.$refs.addressVaildationForm.validate()) {
          this.$store.commit("setGlobalLoading", true);
          let resp = null;
          resp = await this.$store
            .dispatch("publicApi/parseCencusTract", this.requestModel)
            .catch((err) => err);

          this.cencus.show = true;
          // Error Throw
          if (resp.status > 200) {
            this.cencus.errorMessage = resp.data;
            throw resp;
          }

          this.cencus.censusTract = resp.data.tract;
          this.cencus.countyCode = resp.data.county;
          this.cencus.stateCode = resp.data.state;
          this.cencus.errorMessage = null;
        }
      } catch (e) {
        console.error(e.data);
      } finally {
        this.$store.commit("setGlobalLoading", false);
      }
    },
    async validateCLP() {
      this.$store.commit("setGlobalLoading", true);
      if (this.$refs.censustractValidationForm.validate()) {
        const resp = await this.$store
          .dispatch("publicApi/validateCencusTract", this.cencus)
          .catch((err) => err);

        this.result.validatedCLP = resp.status == 200;
        this.result.show = true;

        if (this.result.validatedCLP) {
          this.result.message = `This property or whole address is eligible for CLP.`;
        } else {
          this.result.message = `This property is not eligible for CLP, stay tuned for future programs that may be eligible for this Census Tract.`;
        }

        let histories = this.$cookies.isKey("clp_histories")
          ? this.$cookies.get("clp_histories")
          : [];
        let key = this.requestModel.street.replace(/\s+/g, "").toLowerCase();

        if (histories.length > 0 && histories.some((x) => x.key == key)) {
          histories = histories.filter((x) => x.key != key);
        }

        histories.push({
          key: key,
          requestModel: this.requestModel,
          cencus: this.cencus,
          result: this.result,
          creationDate: new Date().getTime(),
        });

        this.$cookies.set("clp_histories", JSON.stringify(histories));
        this.histories = histories;
      }
      this.$store.commit("setGlobalLoading", false);
    },
    deleteHistory(history) {
      if (confirm("Are you sure you want to delete this history?")) {
        this.histories = this.histories.filter((x) => x.key != history.key);
        this.$cookies.set("clp_histories", JSON.stringify(this.histories));
      }
    },
    reset() {
      this.requestModel = {
        street: null,
        city: null,
        state: null,
        zip: null,
      };
      this.cencus = {
        show: false,
        lockCountyfips: false,
        censusTract: null,
        countyCode: null,
        stateCode: null,
        errorMessage: null,
      };
      this.result = {
        show: false,
        message: null,
        validatedCLP: false,
      };
    },
  },
  mixins: [rules],
};
</script>
